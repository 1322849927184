// import function to register Swiper custom elements
import Swiper from "swiper";
import { Navigation, Autoplay } from "swiper";
// register Swiper custom elements
// register();
// document.addEventListener("DOMContentLoaded", () => {
const slider = new Swiper(".slider", {
  modules: [Autoplay, Navigation],
  loop: true,
  // watchSlidesProgress: true,
  // centeredSlides: true,
  // slidesPerView: 1,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  autoplay: {
    delay: 5000,
  },
  speed: 600,
});

// });
